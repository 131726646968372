<template>
    <v-app id="inspire">
        <div class="px-4">
            <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
            <!-- <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" /> -->
            <v-container class="container--fluid grid-list-md text-left" :style="{ fontSize: '15px',  color: '#1d6499' }">
                <v-icon :style="{ color: '#1d6499',paddingBottom: '11px', marginRight: '5px' }" left-bottom x-large>mdi-cogs</v-icon> Currency <span class="text-h5 font-weight-bold"> ({{totalCurrencies}}) </span>
            </v-container>
            <!-- <v-card elevation="2" outlined shaped tile class="pa-1" v-if="hasPermission('ROLE_TDM_VIEW_CURRENCY')"> -->
            <v-card elevation="2" outlined shaped tile class="pa-1" >
                <div>
                    <v-card-title>
                        <v-container grid-list-md text-xs-center>
                            <v-layout row wrap>
                                <div class="d-flex">
                                    <span class="mt-4 mr-2">Search By:</span>
                                    <v-text-field class="mr-3" label="Country" v-model="query.country" single-line outlined @change="search($event, 'country')" data-cy="module-country"></v-text-field>
                                    <v-text-field label="Num Code" v-model="query.numCode" single-line outlined @change="search($event, 'numCode')" data-cy="module-num-code"></v-text-field>
                                </div>
                            </v-layout>
                        </v-container>
                    </v-card-title>
                </div>
                <div class="d-flex flex-no-wrap justify-space-between mr-lg-10">
                        <v-container grid-list-md text-xs-center>
                            <v-layout wrap class="float-lg-right">
                                <!-- <v-flex xs12 sm6 v-if="hasPermission('ROLE_TDM_VIEW_CURRENCY')"> -->
                                <v-flex xs12 sm4>
                                    <v-btn :block="$vuetify.breakpoint.mdAndDown"  style="min-width: 200px;" class="mt-2" color="primary" @click="resetInputs()" data-cy="button-search">
                                        <v-icon dark left> mdi-history </v-icon> Reset
                                    </v-btn>
                                </v-flex>
                                <v-flex xs12 sm4>
                                    <v-btn :block="$vuetify.breakpoint.mdAndDown" style="min-width: 200px;" class="mt-2" color="primary" @click="queryData(true)" data-cy="button-search">
                                        <v-icon dark left> mdi-magnify</v-icon> Search
                                    </v-btn>
                                </v-flex>
                                <!-- <v-flex xs12 sm6 v-if="hasPermission('ROLE_TDM_CREATE_CURRENCY')"> -->
                                <v-flex xs12 sm4>
                                    <v-btn :block="$vuetify.breakpoint.mdAndDown" style="min-width: 200px;" class="mt-2" color="primary" @click.stop="showCurrencyForm('CREATED')" data-cy="button-create">
                                        <v-icon dark left> mdi-plus</v-icon> Create New Currency
                                    </v-btn>
                                    <currency-form ref="CurrencyForm" :selected-item="selectedCurrency" :is-show="showFormDialog" :form-type="CurrencyFormType" :currencies="currencies" :countries="countries" v-on:close-dialog="closeDialog" v-on:save-currency="saveCurrency" />
                                </v-flex>
                            </v-layout>
                        </v-container>
                </div>
            </v-card>
            <v-data-table :hide-default-header="true" :headers="headers" :items="displayCurrencies" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalCurrencies" class="elevation-2 mt-4 px-4" :footer-props="{'items-per-page-options': [10, 20, 50]}">
                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr :colspan="headers.length">
                            <th v-for="header in headers" :key="header.text">
                                <span class="d-flex justify-center">{{header.text}}</span>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body="{ items }">
                    <tbody>
                        <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                            <td> <span class="d-flex justify-center"> {{ item.name }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.country }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.code }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.numCode }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.symbol }}</span></td>
                            <td>
                                <span class="d-flex justify-center">
                                    <v-btn fab dark x-small color="primary" class="mr-2" @click.stop="showCurrencyForm('VIEW', item)">
                                        <v-icon dark medium>mdi-eye</v-icon>
                                    </v-btn>
                                    <!-- <v-btn fab dark x-small color="green" @click.stop="showDeviceTypeForm('UPDATE', item)" v-if="hasPermission('ROLE_TDM_UPDATE_CURRENCY')"> -->
                                    <v-btn fab dark x-small color="primary" @click.stop="showCurrencyForm('UPDATE', item)">
                                        <v-icon dark medium>mdi-pencil</v-icon>
                                    </v-btn>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning">
                        Found no results.
                    </v-alert>
                </template>
            </v-data-table>
        </div>
    </v-app>
    </template>
    
    <script>
    import API from '@/api/API.js'
    import Loading from '@/views/components/Loading.vue'
    import CurrencyForm from '@/views/components/CurrencyForm.vue'
    import {
        mapGetters
    } from 'vuex';
    
    export default {
        name: 'Currency',
        components: {
            Loading,
            CurrencyForm,   
        },
        data() {
            return {
                //filter/search variables
                filterQry: {
                    qryName: "",
                },
                headers: [],
                countries: [],
                displayCurrencies: [],
                remainderCurrencies: [],
                currencies: [],
                loading: true,
                loadingMessage: "",
                loadingDialog: false,
                loadingFinished: false,
                CurrencyFormType: 'CREATED',
                selectedCurrency: {},
                page: 1,
                totalCurrencies: 0,
                numberOfPages: 0,
                options: {
                    rowsPerPage: 10
                },
                // details: {
                //     title: '',
                //     item: {}
                // },
                query: {
                    country: ''
                },
                // isShowDetails: false,
                showFormDialog: false,
                isHtml: false
            }
        },
        watch: {
            options: {
                handler() {
                    console.log('1')
                    this.queryData()
                    this.setData()
                },
            },
            deep: true,
        },
        computed: {
            //get role attributes
            ...mapGetters([
                'attributes',
                'user',
                'authorities'
            ]),
        },
        async mounted() {
            this.setHeaders()
            //this.queryData()
        },
        methods: {
            resetInputs() {
                this.query.country = ''
                this.query.numCode = ''
                this.queryData();
            },
            hasPermission(authority) {
                if(this.authorities.some(x => x.name === authority)){
                    return true
                }
                return false
            },
            async queryData(isSearch = false) {
                this.loading = true
                if (isSearch) {
                    this.options.page = 1
                }
                const {
                    page,
                    itemsPerPage
                } = this.options;
                let pageNumber = page - 1;
                try {
                    const limit = itemsPerPage
                    const listResponse = await API.searchCurrencies(pageNumber, limit, this.query)
                    console.log(listResponse)
                    if (!listResponse || listResponse.error) {
                        //error getting data
                        console.log(`${listResponse.error}`)
                    } else {
                        var filteredList = listResponse.currencies
                        if (page > 1 && this.remainderCurrencies.length > 0) {
                            filteredList = this.remainderCurrencies.concat(filteredList)
                        }
                        this.totalCurrencies = listResponse.totalItems
                        const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                        this.displayCurrencies = filteredList.slice(0, end)
                        this.remainderCurrencies = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                        this.numberOfPages = listResponse.pageCount
                    }
                    this.loading = false
                } catch (e) {
                    console.log(e)
                    this.loading = false
                }
            },
            async setData() {
                try {
                    const listResponse = await API.getAllCountries()
                    if (!listResponse || listResponse.error) {
                        //error getting data
                        console.log(`${listResponse.error}`)
                    } else {
                        this.countries = listResponse.countries
                    }
                } catch (e) {
                    console.log(e)
                }
            },
            setHeaders() {
                var headers = [
                    {
                        text: 'Currency Name',
                        value: 'name'
                    },
                    {
                        text: 'Country',
                        value: 'country'
                    },
                    {
                        text: 'Alpha Code',
                        value: 'code'
                    },
                    {
                        text: 'Numeric Code',
                        value: 'numCode'
                    }
                    ,
                    {
                        text: 'Symbol',
                        value: 'symbol'
                    },
                    {
                        text: 'Actions',
                        value: 'controls',
                        sortable: false
                    }
                ]
                this.headers = headers
            },
            showCurrencyForm(what, item = {}) {
                this.CurrencyFormType = what
                this.selectedCurrency = item
                this.showFormDialog = true
            },
            closeDialog(type) {
                console.log('type:', type);
                // if (type === 'details') {
                //     this.details.title = ''
                //     this.details.item = {}
                //     this.isShowDetails = false
                // } else 
                if (type === 'CREATED' || type === 'UPDATE' || type === 'VIEW') {
                    this.showFormDialog = false
                } else if (type === 'loading') {
                    this.loadingDialog = false
                    this.queryData();
                } else if (type === 'loading-error') {
                    this.loadingDialog = false
                }
            },
            async saveCurrency(value) {
                this.loadingDialog = true
                this.isHtml = true
                this.loadingFinished = false
                if (value) {
                    console.log(value)
                    if (value.formType === 'CREATED') {
                        this.loadingMessage = `Creating new currency for <strong><i>${value.countryName}</i></strong>`
                        setTimeout(async () => {
                            const createResponse = await API.createCurrency(value)
                            console.log(createResponse)
                            this.loadingFinished = true
                            if (!createResponse || createResponse.error) {
                                this.isHtml = false
                                this.loadingMessage = createResponse.error
                            } else {
                                this.loadingMessage = `Successfully created currency <strong><i>${value.countryName}</i></strong>`
                                this.showFormDialog = false
                                this.$refs.CurrencyForm.resetForm()
                            }
                        }, 2000);
    
                    } else if (value.formType === 'UPDATE') {
                        this.loadingMessage = `Updating currency for <strong><i>${value.countryName}</i></strong>`
                        setTimeout(async () => {
                            const updateResponse = await API.updateCurrency(value)
                            this.loadingFinished = true
                            if (!updateResponse || updateResponse.error) {
                                this.isHtml = false
                                this.loadingMessage = updateResponse.error
                            } else {
                                this.loadingMessage = `Successfully updated currency <strong><i>${value.countryName}</i></strong>`
                                this.showFormDialog = false
                                this.$refs.CurrencyForm.resetForm()
                            }
                        }, 2000);
                    } else {
                        this.loadingMessage = "Invalid command"
                        this.loadingFinished = true
                    }
                } else {
                    this.loadingMessage = "Error processing data."
                    this.loadingFinished = true
                }
            },
            search(event, type) {
                this.query[type] = event
            }
        }
    };
    </script>
    
    <style scoped>
    .v-data-table {
        max-width: 100%
    }
    </style>
    