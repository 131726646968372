<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'CREATED'">Create New Currency</span>
                <span class="headline" v-else-if="formType === 'UPDATE'">Update Currency</span>
                <span class="headline" v-else-if="formType === 'VIEW'">View Currency</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry" :disabled="formType === 'VIEW'" >
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex  xs12 sm8>
                                <v-select
                                    v-model="form.countryName"
                                    :items="countryList"
                                    item-text="name"
                                    item-value="name"
                                    label="Country"
                                    :disabled="formType === 'VIEW' || (formType === 'UPDATE' && countryNameChecker)"
                                    required :rules="[rules.requiredSelection]"
                                    data-cy="form-country-list"
                                    return-object
                                    @change="handleCountryChange">
                                    <template v-slot:label>
                                        <span v-if="formType === 'CREATED'">
                                            Select Country <span style="color: red;">*</span>
                                        </span>
                                    </template>
                                </v-select>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field :disabled="true" label="Numeric Code" v-model="form.numCode" required :rules="[rules.requiredField]" data-cy="form-numeric-code"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Currency Name" v-model="form.name" required :rules="[rules.requiredField]" data-cy="form-currency-name">
                                    <template v-slot:label>
                                        <span v-if="formType !== 'VIEW'">
                                            Input the Currency Name <span style="color: red;">*</span>
                                        </span>
                                    </template>
                                </v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Alpha Code" maxLength="3" v-model="form.code" required :rules="[rules.requiredField]" data-cy="form-code">
                                    <template v-slot:label>
                                        <span v-if="formType !== 'VIEW'">
                                            Input the Alpha Code <span style="color: red;">*</span>
                                        </span>
                                    </template>
                                </v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Symbol" maxLength="1" v-model="form.symbol" required :rules="[rules.requiredField]" data-cy="form-symbol">
                                    <template v-slot:label>
                                        <span v-if="formType !== 'VIEW'">
                                            Input the Symbol <span style="color: red;">*</span>
                                        </span>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small v-if="formType != 'VIEW'">*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="blue darken-1" v-if="formType != 'VIEW'" text v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>

export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
        countries: Array,
    },
    computed: {
        populatedForm() {
            return {
                countryName: this.form.countryName,
                code: this.form.code,
                numCode: this.form.numCode,
                name: this.form.name,
                symbol: this.form.symbol,
                formType: this.formType
            }
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        countries: {
            handler(value) {
                this.countryList = value ? value : [];
            },
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            countryList: this.countries ?? [],
            countryNameChecker: false,
            form: {
                country: {},
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                // requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
                requiredSelection: v => (!!v && Object.keys(v).length > 0) || "Required at least one selection",
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        handleCountryChange(country) {
            console.log('Selected country:', country);
            this.form = {
                countryName: country.name,
                numCode: country.numCode,
                name: this.form.name,
                code: this.form.code,
                symbol: this.form.symbol,
            }
        },
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-currency', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    countryName: undefined,
                    code: undefined,
                    name: undefined,
                    numCode: undefined,
                    symbol: undefined,
                }
            } else if ((this.formType === 'UPDATE' || this.formType === 'VIEW') && this.selectedItem) {
                this.form = {
                    countryName: this.selectedItem.country,
                    code: this.selectedItem.code,
                    name: this.selectedItem.name,
                    numCode: this.selectedItem.numCode,
                    symbol: this.selectedItem.symbol,
                }
                this.countryNameChecker = this.form.countryName != ""
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        onlyForCurrency ($event) {
            // console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

            // only allow number and one dot
            if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.form.amount.indexOf('.') != -1)) { // 46 is dot
                $event.preventDefault();
            }

            // restrict to 2 decimal places
            if(this.form.amount!=null && this.form.amount.indexOf(".")>-1 && (this.form.amount.split('.')[1].length > 1)){
                $event.preventDefault();
            }
        }
    }
}
</script>
